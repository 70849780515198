import React from "react";
import { RiArrowLeftLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

const GoToDashboard = () => {
  const navigate = useNavigate();
  return (
    <button
      onClick={() => navigate("/main/dashboard")}
      className="back_btn flex items-center justify-center text-4xl transition-all group  mb-4"
    >
      <RiArrowLeftLine className="text-black flex items-center justify-center group-hover:-translate-x-[5px]  transition-all duration-150 ease-out" />
    </button>
  );
};

export default GoToDashboard;

import { useState, useEffect } from "react";
import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance";
import { MANAGE_ALLINVESTMENTS_API } from "../../../../../Utilities/APIs/APIs";
import BackButton from "../../../../Partials/Elements/BackButton/BackButton";
import { CRUDTable } from "../../../../Partials/Layout/CRUD/CRUDTable/CRUDTable";
import { CRUDTableHeader } from "../../../../Partials/Layout/CRUD/CRUDTableHeader/CRUDTableHeader";
import { CRUDth } from "../../../../Partials/Layout/CRUD/CRUDth/CRUDth";
import { ShortTextCell } from "../../../../Partials/Layout/CRUD/ShortTextCell/ShortTextCell";
import { takaFormatter } from "../../../../../Utilities/HelperFunctions/takaFormatter";
import { CSVLink } from "react-csv";

const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString().slice(-2);
    return `${day}/${month}/${year}`;
};

const calculateMaturityDate = (dateStr, tenureInDays) => {
    const date = new Date(dateStr);
    if (isNaN(date)) return "Invalid Date";  // Handle invalid date input

    date.setDate(date.getDate() + tenureInDays);
    return formatDate(date);
};

function AllInvestments({ api }) {
    const [investments, setInvestments] = useState([]);
    const [sortOrder, setSortOrder] = useState("asc"); // "asc" for oldest to newest, "desc" for newest to oldest
    const axiosInstance = useAxiosInstance();

    useEffect(() => {
        const fetchInvestments = async () => {
            try {
                const { data } = await axiosInstance.get(`${MANAGE_ALLINVESTMENTS_API}getAllInvestments`);
                // Sort investments based on the selected order
                const sortedInvestments = data.sort((a, b) => {
                    const dateA = new Date(a.date);
                    const dateB = new Date(b.date);
                    return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
                });
                setInvestments(sortedInvestments);
            } catch (error) {
            }
        };

        fetchInvestments();
    }, [api, axiosInstance, sortOrder]);

    const csvHeaders = [
        { label: "Investor", key: "investor" },
        { label: "Company", key: "company" },
        { label: "Amount", key: "amount" },
        { label: "ROI (%)", key: "roi" },
        { label: "Tenure Days", key: "tenure" },
        { label: "Date of Investment", key: "dateOfInvestment" },
        { label: "Date of Maturity", key: "dateOfMaturity" }
    ];

    const csvData = investments.map(inv => ({
        investor: inv.investor.name,
        company: inv.company.name,
        amount: (inv.amount),
        roi: `${inv.rate}%`,
        tenure: inv.tenure,
        dateOfInvestment: formatDate(inv.date),
        dateOfMaturity: calculateMaturityDate(inv.date, inv.tenure)
    }));

    return (
        <div className="screen_wrapper">
            <div className="screen_header">
                <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                    <BackButton />
                    <h1 className="screen_heading">{`All Investments (${investments.length} in total)`}</h1>
                </div>
                <div style={{ display: "flex", gap: "10px" }}>
                    <button
                        onClick={() => setSortOrder(sortOrder === "asc" ? "desc" : "asc")}
                        className="text-blue-500"
                    >
                        {sortOrder === "asc" ? "Oldest to Newest" : "Newest to Oldest"}
                    </button>
                    <CSVLink
                        data={csvData}
                        headers={csvHeaders}
                        filename={`all_investments_${formatDate(new Date())}.csv`}
                        className="text-yellow-400"
                    >
                        Export To CSV
                    </CSVLink>

                </div>
            </div>
            <CRUDTable>
                <CRUDTableHeader>
                    <CRUDth align={"left"} th="Investor" />
                    <CRUDth align={"left"} th="Company" />
                    <CRUDth th="Date of Investment" />
                    <CRUDth th="Date of Maturity" />
                    <CRUDth th="Annualized (%)" />
                    <CRUDth align={"right"} th="Amount" />
                </CRUDTableHeader>
                <tbody>
                    {investments.map(investment => (
                        <tr key={investment._id}>
                            <ShortTextCell align={"left"} text={(investment?.investor?.name || "")} />

                            <td>
                                <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                    <p className="">{investment.company.name}</p>
                                </div>
                            </td>
                            <ShortTextCell text={formatDate(investment.date)} />
                            <ShortTextCell text={calculateMaturityDate(investment.date, investment.tenure)} />
                            <ShortTextCell text={`${investment.rate}%`} />
                            <ShortTextCell align={"right"} text={takaFormatter(investment.amount)} />
                        </tr>
                    ))}
                </tbody>
            </CRUDTable>
        </div>
    );
}

export default AllInvestments;

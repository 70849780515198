import { useContext, useState } from "react";
import { useAxiosInstance } from "../../../../../../../../Utilities/Hooks/useAxiosInstance";
import { MANAGE_INVESTMENT_DOCUMENTS_API } from "../../../../../../../../Utilities/APIs/APIs";
import Form from "../../../../../../../Partials/Forms/Form";
import ShortTextInput from "../../../../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput";
import ImageInput from "../../../../../../../Partials/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../../../../../../Partials/Forms/FormSubmitButton/FormSubmitButton";
import { AuthContext } from "../../../../../../../../Contexts/GlobalContexts/AuthContext";

export default function CreateInvestmentDoc({ targetID, setShowEditSection, triggerFetch }) {
    const axiosInstance = useAxiosInstance();
    const [file, setFile] = useState(null);
    const [documentName, setDocumentName] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const { employee } = useContext(AuthContext);



    async function handleSubmit(e) {
        e.preventDefault();
        setIsLoading(true);
        setErrorMessage(""); // Clear previous error message

        const formData = new FormData();
        formData.append('investment', targetID);
        formData.append('file', file);
        formData.append('documentName', documentName);
        formData.append('createdBy', employee._id);

        try {
            const response = await axiosInstance.post(
                `${MANAGE_INVESTMENT_DOCUMENTS_API}upload/${targetID}`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            if (response?.data) {
                setFile(null);
                setDocumentName("");
                setShowEditSection(false);
                triggerFetch();
            }
        } catch (error) {
            setErrorMessage(error?.response?.data?.message || "Failed to upload file. Try Again");
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Form onSubmit={handleSubmit}>
            <div>
                <ShortTextInput
                    label={"Document Name"}
                    value={documentName}
                    setState={setDocumentName}
                />
                <ImageInput
                    allowCreateImage
                    state={file}
                    setState={setFile}
                    fieldId={"Investment Document"}
                    isFile={true}
                >
                    <p>Investment Document</p>
                </ImageInput>
            </div>

            {errorMessage && (
                <div className="text-red-500 mb-4">{errorMessage}</div>
            )}

            <FormSubmitButton text={isLoading ? "Uploading..." : "Upload"} disabled={isLoading}>
                {isLoading ? (
                    <div className="flex items-center space-x-2">
                        <div className="w-4 h-4 border-2 border-t-2 border-t-transparent border-gray-500 rounded-full animate-spin"></div>
                        <span>Uploading...</span>
                    </div>
                ) : (
                    "Upload"
                )}
            </FormSubmitButton>
        </Form>
    );
}

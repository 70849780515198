import { ModalHead } from "./ModalHead";


export function UserModal({ modalHeading, children, extraClass, setShowModalContent }) {
    return (
        <div className={`fixed inset-0 z-[9999999] ${extraClass}`}>
            <div 
                className="fixed inset-0 bg-[rgba(112,112,112,0.82)]" 
                onClick={() => setShowModalContent(false)}
            ></div>
            <div className="fixed inset-0 flex items-center justify-center p-4 overflow-y-auto">
                <div className="relative w-3/4  bg-black rounded-lg shadow-lg p-8">
                    <ModalHead
                        modalHeading={modalHeading}
                        setShowModalContent={setShowModalContent}
                    />
                    <div className="mt-4 overflow-y-auto max-h-[80vh]">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}
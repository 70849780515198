import { useState } from "react";
import { useAxiosInstance } from "../../../../../../Utilities/Hooks/useAxiosInstance";
import { MANAGE_TOKEN_IMAGES_API } from "../../../../../../Utilities/APIs/APIs";
import Form from "../../../../../Partials/Forms/Form";
import ImageInput from "../../../../../Partials/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../../../../Partials/Forms/FormSubmitButton/FormSubmitButton";
import ShortTextInput from "../../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput";

export default function CreateTokenImage({ targetID, setShowEditSection, triggerFetch }) {
    const axiosInstance = useAxiosInstance();
    const [file, setFile] = useState(null);
    const [imageName, setimageName] = useState("");

    async function handleSubmit(e) {
        e.preventDefault();

        const formData = new FormData();
        formData.append('token', targetID);
        formData.append('file', file);
        formData.append('imageName', imageName);

        try {
            const response = await axiosInstance.post(
                MANAGE_TOKEN_IMAGES_API + "createTokenImage",
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            if (response?.data) {
           
                // Reset form
                setFile(null);
                setShowEditSection(false);
                triggerFetch();

            }
        } catch (error) {
        }
    }

    return (
        <Form onSubmit={handleSubmit}>
            <div>
                <ShortTextInput
                    label={"Image Name"}
                    value={imageName}
                    setState={setimageName}
                />
                <ImageInput
                    allowCreateImage
                    state={file}
                    setState={setFile}
                    fieldId={"Token Document"}

                ><p>Token Image</p></ImageInput>

            </div>

            <FormSubmitButton text="Upload" />
        </Form>
    );
}


// const exportToCSV = (investors) => {
//   const headers = ["Name", "Email Address", "Status"];
//   const rows = investors.map(investor => [
//     investor.name,
//     investor.email,
//     investor.isActive ? "Active" : "Inactive"
//   ]);

import { useContext, useMemo, useState } from "react";
import { AuthContext } from "../../../../../../../Contexts/GlobalContexts/AuthContext";
import { useAxiosInstance } from "../../../../../../../Utilities/Hooks/useAxiosInstance";
import { useParams } from "react-router-dom";
import { useGetInfo } from "antopolis-react-utils/hooks";
import BackButton from "../../../../../../Partials/Elements/BackButton/BackButton";
import { CreateButton } from "../../../../../../Partials/Layout/CRUD/CreateButton/CreateButton";
import { CRUDTable } from "../../../../../../Partials/Layout/CRUD/CRUDTable/CRUDTable";
import { CRUDTableHeader } from "../../../../../../Partials/Layout/CRUD/CRUDTableHeader/CRUDTableHeader";
import { CRUDth } from "../../../../../../Partials/Layout/CRUD/CRUDth/CRUDth";
import { ShortTextCell } from "../../../../../../Partials/Layout/CRUD/ShortTextCell/ShortTextCell";
import CreateInvestorUser from "../../../../WCScreen/InvestmentUser/CreateInvestorUser";
import { MANAGE_INVESTOR_USERS_API, MANAGE_Investors_API } from "../../../../../../../Utilities/APIs/APIs";
import { Modal } from "../../../../../../Partials/Elements/Modal/Modal";


//   const csvContent =
//     "data:text/csv;charset=utf-8," +
//     [headers.join(","), ...rows.map(row => row.join(","))].join("\n");

//   const encodedUri = encodeURI(csvContent);
//   const link = document.createElement("a");
//   link.setAttribute("href", encodedUri);
//   link.setAttribute("download", "investors.csv");
//   document.body.appendChild(link);

//   link.click();
//   document.body.removeChild(link);
// };

function SingleInvestorsInvestorUsers({ api }) {
    const [toggleFetch, setToggleFetch] = useState(false);
    const [showUpdateForm, setShowUpdateForm] = useState(false);
    const [targetID, setTargetID] = useState(null);
    const [showCreateForm, setShowCreateForm] = useState(false);
    const { employee } = useContext(AuthContext);
    const axiosInstance = useAxiosInstance();

    const { id: investorId } = useParams();

    const { info: investorUsersInvestors } = useGetInfo({
        axiosInstance: axiosInstance,
        api: api + "getAllInvestorUserOfInvestor/" + investorId,
        toggleFetch,
    });

    const { info: investor } = useGetInfo({
        axiosInstance: axiosInstance,
        api:  MANAGE_Investors_API + "getSingleInvestor/" + investorId,
        toggleFetch,
    });




    // const navigate = useNavigate();

    // const handleRowClick = (e, investor) => {
    //     const isButtonClick = e.target.closest(".wc_investor_action_buttons");
    //     if (!isButtonClick) {
    //         navigate("/main/investments/" + investor._id);
    //     }
    // };

    function triggerFetch() {
        setToggleFetch((prevState) => !prevState);
    }

    const sortedInvestorUsersInvestors = useMemo(() => {
        if (!investorUsersInvestors) return [];
        return investorUsersInvestors
            .slice()
            .sort((a, b) => (a.name || "").localeCompare(b.name || ""));
    }, [investorUsersInvestors]);

    return (
        <div className="screen_wrapper">
            <div className="screen_header">
                <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                    <BackButton />
                    <h1 className="screen_heading">{`${investor ? investor?.name : ""}'s Investment Users (${investorUsersInvestors?.length || 0} in total)`}</h1>
                </div>
                <div style={{ display: "flex", gap: "20px" }}>
                    <CreateButton
                        screenTopicSingular={"Investment User"}
                        setShowCreateForm={setShowCreateForm}
                    />
                    {/* <CRUDButton handleClick={() => exportToCSV(sortedInvestors)}>
            <p style={{ fontSize: "16px", color: "#FFE09D", textAlign: "center" }}>Export To CSV</p>
          </CRUDButton> */}
                </div>
            </div>
            <CRUDTable>
                <CRUDTableHeader>
                    {/* <CRUDth th="" /> */}
                    <CRUDth align={"left"} th="Investment User" />
                    <CRUDth  th="Email" />
                    {/* <CRUDth th="" /> */}

                </CRUDTableHeader>
                <tbody>
                    {sortedInvestorUsersInvestors && sortedInvestorUsersInvestors.length > 0 &&
                        sortedInvestorUsersInvestors.map((investorUsersInvestor) => (
                            <tr
                                key={investorUsersInvestor._id}
                            // className="investor_link"
                            // onClick={(e) => handleRowClick(e, investorUsersInvestor)}
                            >
                                {/* <ShortTextCell align={"right"} text={""} /> */}

                                <td>
                                    <div style={{ display: "flex", alignItems: "center", gap: "5px", justifyContent: "start" }}>
                                        <img
                                            style={{ height: "30px", width: "30px", borderRadius: "50%" }}
                                            src={process.env.REACT_APP_SPACES_URL + investorUsersInvestor?.investorUser?.image}
                                            alt=""
                                        />
                                        <p>{investorUsersInvestor?.investorUser?.name}</p>
                                    </div>
                                </td>
                                {/* <ShortTextCell text={investorUsersInvestor?.investorUser?.name} /> */}
                                <ShortTextCell text={investorUsersInvestor?.investorUser?.email} />
                                {/* <ShortTextCell align={"right"} text={""} /> */}

                                {/* <ShortTextCell
                                    text={investorUsersInvestor.isActive ? "Active" : "Inactive"}
                                /> */}
                                {/*   <td className="action_buttons_wrapper">
                                    <div className="action_buttons wc_investor_action_buttons">
                                         <EditButton
                                            setShowUpdateForm={setShowUpdateForm}
                                            targetID={investorUsersInvestor._id}
                                            setTargetID={setTargetID}
                                        />
                                        <CRUDButton handleClick={() => navigate(`/main/investmentsGraph/${investorUser._id}`)}>
                                            <BsFileEarmarkBarGraph size={25} />
                                        </CRUDButton> 
                                    </div>
                                </td> */}
                            </tr>
                        ))}
                </tbody>
            </CRUDTable>


            {showCreateForm && (
                <Modal
                    extraClass={"small"}
                    modalHeading={`Create Investment User`}
                    setShowModalContent={setShowCreateForm}
                >
                    <CreateInvestorUser
                        api={MANAGE_INVESTOR_USERS_API}
                        employee={employee}
                        setShowCreateForm={setShowCreateForm}
                        triggerFetch={triggerFetch}
                    />
                </Modal>
            )}  {/* 
      {showUpdateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Update Investor`}
          setShowModalContent={setShowUpdateForm}
        >
          <UpdateInvestor
            api={MANAGE_Investors_API}
            employee={employee}
            setShowUpdateForm={setShowUpdateForm}
            targetID={targetID}
            triggerFetch={triggerFetch}
          />
        </Modal>
      )} */}
        </div>
    );
}

export default SingleInvestorsInvestorUsers;

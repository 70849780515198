import { RiArrowDownSLine } from "react-icons/ri";

function CRUDSelectHeader({ value, setState, active, children, label }) {
  return (
    <th
      style={{
        textAlign: "center",
        fontSize: "16px",
        cursor: "progress",
      }}
      className="relative whitespace-nowrap "
    >
      <div className="flex items-center justify-center h-fit">
        <p className="text-white cursor-pointer">{label}</p>
        <RiArrowDownSLine />
        <select
          className="w-full absolute inset-0 opacity-0"
          onChange={(e) => setState(e.target.value)}
        >
          {children}
        </select>
      </div>
    </th>
  );
}

export default CRUDSelectHeader;

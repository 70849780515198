import { useContext, useEffect, useMemo, useState } from "react";
import { DataContext } from "../../../../../Contexts/GlobalContexts/DataContext";
import { AuthContext } from "../../../../../Contexts/GlobalContexts/AuthContext";
import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance";
import { useGetInfo } from "antopolis-react-utils/hooks";
import { MANAGE_TOKEN_REQUESTS_API } from "../../../../../Utilities/APIs/APIs";
import { useNavigate } from "react-router-dom";
import BackButton from "../../../../Partials/Elements/BackButton/BackButton";
import SelectInput from "../../../../Partials/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../../../Partials/Forms/FormInputs/SelectInput/SelectOption/SelectOption";
import { CRUDTable } from "../../../../Partials/Layout/CRUD/CRUDTable/CRUDTable";
import { CRUDTableHeader } from "../../../../Partials/Layout/CRUD/CRUDTableHeader/CRUDTableHeader";
import { CRUDth } from "../../../../Partials/Layout/CRUD/CRUDth/CRUDth";
import { ShortTextCell } from "../../../../Partials/Layout/CRUD/ShortTextCell/ShortTextCell";
import { takaFormatter } from "../../../../../Utilities/HelperFunctions/takaFormatter";
import { formatDate } from "../../../../../Utilities/HelperFunctions/formatDate";
import { Modal } from "../../../../Partials/Elements/Modal/Modal";
import ViewReceipt from "../PendingRequest/ViewReceipt";
import UserDetails from "./UserDetails";
import NanoAssetModal from "./NanoAssetModal";
import CRUDSelectHeader from "../../../../Partials/Layout/CRUD/CRUDSelectHeader/CRUDSelectHeader";
import { UserModal } from "./UserModal/UserModal";

function AllRequest({ api }) {
  const [selectedReceipt, setSelectedReceipt] = useState(null);
  const [showReceiptSection, setShowReceiptSection] = useState(false);
  const [selectedUserInfo, setSelectedUserInfo] = useState(null);
  const [showNameSection, setShowNameSection] = useState(false);
  const [showNanoAssets, setShowNanoAssets] = useState(false);
  const [selectedNanoAsset, setSelectedNanoAsset] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  const { toggleFetch, triggerFetch } = useContext(DataContext);
  const [tokenRequests, setTokenRequests] = useState([]);
  const [filter, setFilter] = useState("buy");
  const [statusFilter, setStatusFilter] = useState("");
  const [targetID, setTargetID] = useState(null);

  const { employee } = useContext(AuthContext);
  const axiosInstance = useAxiosInstance();

  const { info } = useGetInfo({
    axiosInstance: axiosInstance,
    api: MANAGE_TOKEN_REQUESTS_API + "getAllRequest",
    toggleFetch,
  });

  useEffect(() => {
    info?.success && setTokenRequests(info.data);
  }, [info]);

  const navigate = useNavigate();

  const handleFilterChange = (value) => {
    setFilter(value);
  };

  const handleStatusFilterChange = (value) => {
    setStatusFilter(value);
  };

  const handleImageClick = (img) => {
    setSelectedReceipt(img);
    setShowReceiptSection(true);
  };

  const handleNameClick = (name) => {
    setSelectedUserInfo(name);
    setShowNameSection(true);
  };

  const handleNanoAsset = (asset) => {
    setSelectedNanoAsset(asset);
    setShowNanoAssets(true);
  };

  const handleSort = (key) => {
    setSortConfig((prev) => ({
      key,
      direction: prev.key === key && prev.direction === "asc" ? "desc" : "asc",
    }));
  };

  const getSortIndicator = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "asc" ? " (asc)" : " (desc)";
    }
    return "";
  };

  const handleSelectSort = (value) => {
    setSortConfig((prev) => ({
      key: value.split("_")[0],
      direction: value.split("_")[1],
    }));
  };



  const filteredTokenRequests = useMemo(() => {
    if (!tokenRequests) return [];

    return tokenRequests
      .filter((request) => {
        if (filter === "buy" && request.requestType !== "buy") return false;
        if (filter === "sell" && request.requestType !== "sell") return false;
        if (statusFilter === "approved" && request.status !== "approved")
          return false;
        if (statusFilter === "rejected" && request.status !== "rejected")
          return false;
        if (statusFilter === "pending" && request.status !== "pending")
          return false;
        return true;
      })
      .sort((a, b) => {
        if (!sortConfig.key) return 0;

        let aValue, bValue;

        switch (sortConfig.key) {
          case "name":
            aValue = a.tokenInvestor?.name?.toLowerCase() || "";
            bValue = b.tokenInvestor?.name?.toLowerCase() || "";
            break;
          case "token":
            aValue = a.token?.tokenName?.toLowerCase() || "";
            bValue = b.token?.tokenName?.toLowerCase() || "";
            break;
          case "quantity":
            aValue = parseFloat(a.quantity);
            bValue = parseFloat(b.quantity);
            break;
          case "total":
            aValue = parseFloat(a.totalAmount);
            bValue = parseFloat(b.totalAmount);
            break;
          case "date":
            aValue = new Date(a.createdAt);
            bValue = new Date(b.createdAt);
            break;
          default:
            return 0;
        }

        if (aValue < bValue) return sortConfig.direction === "asc" ? -1 : 1;
        if (aValue > bValue) return sortConfig.direction === "asc" ? 1 : -1;
        return 0;
      });
  }, [tokenRequests, filter, statusFilter, sortConfig]);

  return (
    <div className="screen_wrapper">
      <div className="screen_header min-h-fit">
        <div
          style={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            gap: "20px",
            justifyContent: "flex-start",
          }}
        >
          <BackButton />
          <h1 className="screen_heading">{`${
            statusFilter.toUpperCase() || "ALL"
          } Requests (${filteredTokenRequests.length} in total)`}</h1>
        </div>

        <div className="flex justify-center flex-1">
          <div style={{ display: "flex", gap: "20px" }}>
            <SelectInput
              label={""}
              value={statusFilter}
              placeholder={"Please Select Status"}
              setState={handleStatusFilterChange}
            >
              <SelectOption optionValue={""} optionText={"All"} />
              <SelectOption optionValue={"approved"} optionText={"Approved"} />
              <SelectOption optionValue={"rejected"} optionText={"Declined"} />
              <SelectOption optionValue={"pending"} optionText={"Pending"} />
            </SelectInput>

            <SelectInput
              label={""}
              value={filter}
              placeholder={"Please Select Filter"}
              setState={handleFilterChange}
            >
              <SelectOption optionValue={"buy"} optionText={"Buy"} />
              <SelectOption optionValue={"sell"} optionText={"Sell"} />
            </SelectInput>
          </div>
        </div>

        <div className="flex-1" style={{ display: "flex", gap: "20px" }}></div>
      </div>

      <CRUDTable>
        <CRUDTableHeader>
          <CRUDSelectHeader label={"Name"} setState={handleSelectSort}>
            <SelectOption optionValue="name_asc" optionText="A - Z" />
            <SelectOption optionValue="name_desc" optionText="Z - A" />
          </CRUDSelectHeader>
          <CRUDth th="Transaction Id" />
          <CRUDSelectHeader label={"Nano Asset"} setState={handleSelectSort}>
          
            <SelectOption optionValue={"token_asc"} optionText={"A - Z"} />
            <SelectOption optionValue={"token_desc"} optionText={"Z - A"} />
          </CRUDSelectHeader>
          <CRUDth th="Asset Id" />
          <CRUDSelectHeader label={"Quantity"} setState={handleSelectSort}>
            <SelectOption
              optionValue={"quantity_asc"}
              optionText={"Lowest to Highest"}
            />
            <SelectOption
              optionValue={"quantity_desc"}
              optionText={"Highest to Lowest"}
            />
          </CRUDSelectHeader>

          <CRUDSelectHeader label={"Total"} setState={handleSelectSort}>
            <SelectOption
              optionValue={"total_asc"}
              optionText={"Lowest to Highest"}
            />
            <SelectOption
              optionValue={"total_desc"}
              optionText={"Highest to Lowest"}
            />
          </CRUDSelectHeader>

          <CRUDSelectHeader label={"Date"} setState={handleSelectSort}>
            <SelectOption
              optionValue={"date_asc"}
              optionText={"Oldest to Newest"}
            />
            <SelectOption
              optionValue={"date_desc"}
              optionText={"Newest to Oldest"}
            />
          </CRUDSelectHeader>

          <CRUDth th="Receipt" />
          <CRUDth th="Status" />
        </CRUDTableHeader>
        <tbody>
          {filteredTokenRequests.map((tokenRequest, indx) => (
            <tr key={indx}>
              <td onClick={() => handleNameClick(tokenRequest)}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                    cursor: "pointer",
                  }}
                >
                  <img
                    style={{
                      height: "30px",
                      width: "30px",
                      borderRadius: "50%",
                    }}
                    src={
                      tokenRequest.image
                        ? process.env.VITE_REACT_APP_SPACES_URL +
                          tokenRequest?.tokenInvestor?.image
                        : `https://ui-avatars.com/api/?name=${tokenRequest?.tokenInvestor?.name}&background=random&length=1`
                    }
                    alt={tokenRequest.tokenInvestor?.name}
                  />
                  <p>{tokenRequest.tokenInvestor?.name}</p>
                </div>
              </td>
            
              <ShortTextCell
                text={
                  tokenRequest.token?._id?.slice(0, 15) +
                  (tokenRequest.token?._id.length > 15 ? "..." : "")
                }
                tooltipText={tokenRequest.token?._id}
              />
              <ShortTextCell
                onClick={() => handleNanoAsset(tokenRequest)}
                text={
                  tokenRequest.token?.tokenName?.slice(0, 15) +
                  (tokenRequest.token?.tokenName?.length > 15 ? "..." : "")
                }
                tooltipText={tokenRequest.token?.tokenName}
              />
                <ShortTextCell
                text={
                  tokenRequest.token?.tokenCode?.slice(0, 15) +
                  (tokenRequest.token?.tokenCode.length > 15 ? "..." : "")
                }
                tooltipText={tokenRequest.token?.tokenCode}
              />
              <ShortTextCell text={tokenRequest?.quantity} />
              <ShortTextCell text={takaFormatter(tokenRequest.totalAmount)} />
              <ShortTextCell text={formatDate(tokenRequest.createdAt)} />

              <td>
                <div
                  onClick={() => handleImageClick(tokenRequest.receipt)}
                  className="flex items-center justify-center cursor-pointer"
                >
                  <img
                    src={
                      process.env.REACT_APP_SPACES_URL + tokenRequest?.receipt
                    }
                    alt="receipt"
                    className="w-28 h-28"
                  />
                </div>
              </td>
              <td className="">
                <div
                  className={`flex items-center justify-center font-bold ${
                    tokenRequest.status === "approved"
                      ? "text-green-500"
                      : tokenRequest.status === "rejected"
                      ? "text-red-500"
                      : tokenRequest.status === "pending"
                      ? "text-yellow-500"
                      : ""
                  }`}
                >
                  {tokenRequest.status}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </CRUDTable>

      {showReceiptSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`View Receipt`}
          setShowModalContent={setShowReceiptSection}
        >
          <ViewReceipt
            imageSrc={process.env.REACT_APP_SPACES_URL + selectedReceipt}
          />
        </Modal>
      )}

      {showNameSection && (
        <UserModal
          extraClass={"small"}
          modalHeading={`My Profile`}
          setShowModalContent={setShowNameSection}
        >
          <UserDetails selectedUserInfo={selectedUserInfo} />
        </UserModal>
      )}

      {showNanoAssets && (
        <NanoAssetModal
          isOpen={showNanoAssets}
          onClose={() => setShowNanoAssets(false)}
          nanoAsset={selectedNanoAsset}
        />
      )}
    </div>
  );
}

export default AllRequest;

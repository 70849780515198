import React from "react";

import { RiGroupLine } from "react-icons/ri";
import { ScreenHolder } from "../../../Partials/Layout/ScreenHolder/ScreenHolder";
import { NavCardList } from "../../../Partials/Layout/NavCardList/NavCardList";
import { NavCard } from "../../../Partials/Layout/NavCardList/NavCard/NavCard";
import { GiToken } from "react-icons/gi";
// import { CgSandClock } from "react-icons/cg";
import { FaObjectGroup } from "react-icons/fa6";

import { RiTokenSwapFill } from "react-icons/ri";
import GoToDashboard from "../../../Partials/Elements/GoToDashboard";

function TokenScreen() {
  return (
    <ScreenHolder>
      <GoToDashboard/>
      <NavCardList numOfCards={"two"}>
        <NavCard
          cardName={"Nano Assets Investors"}
          navCardLink="/main/nanoAssetInvestors"
        >
          <RiGroupLine />
        </NavCard>
        <NavCard
          cardName={"Nano Assets"}
          navCardLink="/main/AllNanoAssets"
        >
          <GiToken />
        </NavCard>
        {/* <NavCard
          cardName={"Pending Requests"}
          navCardLink="/main/PendingRequests"
        >
          <CgSandClock />
        </NavCard> */}
        <NavCard
          cardName={"Requests"}
          navCardLink="/main/allRequests"
        >
          <RiTokenSwapFill />
        </NavCard>
        <NavCard
          cardName={"Website Content"}
          navCardLink="/main/nanoAsset/websiteContent"
        >
          <FaObjectGroup />
        </NavCard>
      </NavCardList>
    </ScreenHolder>
  );
}

export default TokenScreen;

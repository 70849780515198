import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance";
import { useState } from "react";
import Form from "../../../../Partials/Forms/Form";
import DateInput from "../../../../Partials/Forms/FormInputs/DateInput/DateInput";
import NumberInput from "../../../../Partials/Forms/FormInputs/NumberInput/NumberInput";
import ShortTextInput from "../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput";
import FormSubmitButton from "../../../../Partials/Forms/FormSubmitButton/FormSubmitButton";
import ImageInput from "../../../../Partials/Forms/FormInputs/ImageInput/ImageInput";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import SelectInput from "../../../../Partials/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../../../Partials/Forms/FormInputs/SelectInput/SelectOption/SelectOption";

export default function CreateToken({
  api,
  targetID,
  setShowEditSection,
  triggerFetch,
}) {
  const axiosInstance = useAxiosInstance();

  // Form state
  const [tokenName, setTokenName] = useState("");
  const [thumbnail, setThumbnail] = useState(null);
  const [tokenDescription, setTokenDescription] = useState("");
  const [totalIssuedTokens, setTotalIssuedTokens] = useState(0);
  const [lockInPeriod, setLockInPeriod] = useState(0);
  const [tenure, setTenure] = useState(0);
  const [riskScore, setRiskScore] = useState("");
  const [finalInvestmentDate, setFinalInvestmentDate] = useState(null);
  const [initialTokenValue, setInitialTokenValue] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [roi, setRoi] = useState(0);
  const [maxRoi, setMaxRoi] = useState(0);
  const [category, setCategory] = useState("");
  const [tokenCode, setTokenCode] = useState("");
  const [errors, setErrors] = useState({});
  const [formError, setFormError] = useState("");

  function validateForm() {
    let newErrors = {};
    const tokenCodePattern = /^[a-zA-Z0-9]{5}$/;
    if (!tokenCodePattern.test(tokenCode)) {
      newErrors.tokenCode =
        "Nano Asset code should be exactly 5 alphanumeric characters.";
    }

    if (!tokenName) {
      newErrors.tokenName = "Nano Asset name is required.";
    }

    if (!quantity) {
      newErrors.quantity = "Quantity is required.";
    }

    if (!totalIssuedTokens) {
      newErrors.totalIssuedTokens = "Total issued Nano Assets is required.";
    }

    if (!lockInPeriod) {
      newErrors.lockInPeriod = "Maturity period is required.";
    }

    if (!tenure) {
      newErrors.tenure = "Dividend is required.";
    }
    if (!riskScore) {
      newErrors.riskScore = "Risk score is required.";
    }
    if (!category) {
      newErrors.category = "Category is required.";
    }

    if (!finalInvestmentDate) {
      newErrors.finalInvestmentDate = "Final investment date is required.";
    }

    if (!initialTokenValue) {
      newErrors.initialTokenValue = "Initial nano asset value is required.";
    }

    if (!roi) {
      newErrors.roi = "Minimum ROI is required.";
    }

    if (!maxRoi) {
      newErrors.maxRoi = "Maximum ROI is required.";
    }

    if (!thumbnail) {
      newErrors.thumbnail = "Nano AssetThumbnail is required.";
    }

    if (!tokenDescription) {
      newErrors.tokenDescription = "Nano Asset description is required.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }

  async function handleSubmit(e) {
    e.preventDefault();

    if (!validateForm()) {
      setFormError("Please fill all the required fields correctly!");
      return;
    }

    const formData = new FormData();
    formData.append("investor", targetID);
    formData.append("tokenName", tokenName);
    formData.append("thumbnail", thumbnail);
    formData.append("tokenDescription", tokenDescription);
    formData.append("totalIssuedTokens", totalIssuedTokens);
    formData.append("lockInPeriod", lockInPeriod);
    formData.append("tenure", tenure);
    formData.append("riskScore", riskScore);
    formData.append("finalInvestmentDate", finalInvestmentDate);
    formData.append("initialTokenValue", initialTokenValue);
    formData.append("quantity", quantity);
    formData.append("roi", roi);
    formData.append("maxRoi", maxRoi);
    formData.append("category", category);
    formData.append("tokenCode", tokenCode);

    try {
      const response = await axiosInstance.post(api + "createToken", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response?.data) {
        setTokenName("");
        setThumbnail(null);
        setTokenDescription("");
        setTotalIssuedTokens(0);
        setLockInPeriod(0);
        setTenure(0);
        setRiskScore("Low");
        setFinalInvestmentDate(new Date());
        setInitialTokenValue(0);
        setQuantity(0);
        setRoi(0);
        setMaxRoi(0);
        setCategory("");
        setTokenCode("");
        setShowEditSection(false);
        triggerFetch();
      }
    } catch (error) {
      setErrors({});
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        if (
          error.response.data.message ===
          "Nano Asset With Same Code Already Exists"
        ) {
          setErrors({ tokenCode: error.response.data.message });
          setFormError("Nano Asset With Same Code Already Exists");
        } else {
          setFormError(error.response.data.message);
        }
      }


    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <div className="grid grid-cols-2 gap-20 p-10">
        <div className="">
          <ShortTextInput
            label={"1. Nano Asset Name"}
            value={tokenName}
            placeholder={"Enter Nano Asset Name"}
            setState={setTokenName}
          />

          {errors.tokenName && (
            <div className="mb-2 error_message">{errors.tokenName}</div>
          )}

          <ShortTextInput
            label={"2. Nano Asset Code"}
            value={tokenCode}
            placeholder={"Enter Nano Asset Code"}
            setState={setTokenCode}
          />
          {errors.tokenCode && (
            <div className="mb-2 error_message">{errors.tokenCode}</div>
          )}

          <NumberInput
            label={"3. Quantity"}
            value={quantity}
            placeholder={"Enter Total Quantity"}
            setState={setQuantity}
          />
          {errors.quantity && (
            <div className="mb-2 error_message">{errors.quantity}</div>
          )}

          <NumberInput
            label={"4. Total Issued Nano Assets"}
            value={totalIssuedTokens}
            placeholder={"Enter Total Issued Nano Assets"}
            setState={setTotalIssuedTokens}
          />
          {errors.totalIssuedTokens && (
            <div className="mb-2 error_message">{errors.totalIssuedTokens}</div>
          )}

          <NumberInput
            label={"5. Maturity Period (Days)"}
            value={lockInPeriod}
            placeholder={"Enter Maturity Period in Days"}
            setState={setLockInPeriod}
          />
          {errors.lockInPeriod && (
            <div className="mb-2 error_message">{errors.lockInPeriod}</div>
          )}
          <SelectInput
            label={"6. Dividend"}
            placeholder={"Select an Investor"}
            required
            setState={setTenure}
          >
            <SelectOption optionValue={""} optionText={"Select Dividend"} />
            <SelectOption optionValue={30} optionText={"Monthly"} />
            <SelectOption optionValue={120} optionText={"Quarterly"} />
            <SelectOption optionValue={180} optionText={"Semi Annual"} />
            <SelectOption optionValue={365} optionText={"Annual"} />
          </SelectInput>

          {errors.tenure && (
            <div className="mb-2 error_message">{errors.tenure}</div>
          )}

          <SelectInput
            label={"7. Risk Score"}
            placeholder={"Select a Risk Score"}
            required
            setState={setRiskScore}
          >
            <SelectOption optionValue={0} optionText={"Select Risk Score"} />
            <SelectOption optionValue={"Low"} optionText={"Low"} />
            <SelectOption optionValue={"Medium"} optionText={"Medium"} />
            <SelectOption optionValue={"High"} optionText={"High"} />
          </SelectInput>

          {errors.riskScore && (
            <div className="mb-2 error_message">{errors.riskScore}</div>
          )}

          <SelectInput
            label={"8. Category"}
            placeholder={"Select a Category"}
            required
            setState={setCategory}
          >
            <SelectOption optionValue={""} optionText={"Select Category"} />
            <SelectOption
              optionValue={"Real Estate"}
              optionText={"Real Estate"}
            />
            <SelectOption optionValue={"Business"} optionText={"Business"} />
            <SelectOption
              optionValue={"Working Capital"}
              optionText={"Working Capital"}
            />
            <SelectOption
              optionValue={"Trade Financing"}
              optionText={"Trade Financing"}
            />
            <SelectOption
              optionValue={"Capital Market and Mutual Funds"}
              optionText={"Capital Market and Mutual Funds"}
            />
            <SelectOption
              optionValue={"Gold and Silver"}
              optionText={"Gold and Silver"}
            />
          </SelectInput>

          {errors.category && (
            <div className="mb-2 error_message">{errors.category}</div>
          )}

          <DateInput
            label={"9. Final Investment Date"}
            value={finalInvestmentDate}
            placeholder={"Enter Final Investment Date"}
            setState={setFinalInvestmentDate}
          />
          {errors.finalInvestmentDate && (
            <div className="mb-2 error_message">
              {errors.finalInvestmentDate}
            </div>
          )}

          <NumberInput
            label={"10. Initial Nano Asset Value"}
            value={initialTokenValue}
            placeholder={"Enter Initial Nano Asset Value"}
            setState={setInitialTokenValue}
          />
          {errors.initialTokenValue && (
            <div className="mb-2 error_message">{errors.initialTokenValue}</div>
          )}
        </div>
        <div>
          <ImageInput
            allowCreateImage
            state={thumbnail}
            setState={setThumbnail}
            fieldId={"tokenThumbnail"}
          >
            11. Nano Asset Thumbnail
          </ImageInput>
          {errors.thumbnail && (
            <div className="mt-4 error_message">{errors.thumbnail}</div>
          )}

          <NumberInput
            label={"12. Minimum ROI (%)"}
            value={roi}
            placeholder={"Enter Minimum ROI"}
            setState={setRoi}
          />
          {errors.roi && <div className="mb-2 error_message">{errors.roi}</div>}

          <NumberInput
            label={"13. Maximum ROI (%)"}
            value={maxRoi}
            placeholder={"Enter Maximum ROI"}
            setState={setMaxRoi}
          />

          {errors.maxRoi && (
            <div className="mb-2 error_message">{errors.maxRoi}</div>
          )}

          <div className="mt-10 form-group">
            <label className="input_field_label">
              14. Nano Asset Description
            </label>
            <CKEditor
              editor={ClassicEditor}
              data={tokenDescription}
              onChange={(event, editor) => {
                const data = editor.getData();
                setTokenDescription(data);
              }}
            />
          </div>
          {errors.tokenDescription && (
            <div className="mt-4 error_message">{errors.tokenDescription}</div>
          )}
        </div>
      </div>

      {formError && (
        <div className="text-center mx-auto w-full text-xl text-red-500">
          <p>{formError}</p>
        </div>
      )}

      <FormSubmitButton text="Create Nano Asset" />
    </Form>
  );
}

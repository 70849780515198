import { useState } from "react";
import { useAxiosInstance } from "../../../../../../Utilities/Hooks/useAxiosInstance";
import { useGetInfo } from "antopolis-react-utils/hooks";
import BackButton from "../../../../../Partials/Elements/BackButton/BackButton";
import { CRUDTable } from "../../../../../Partials/Layout/CRUD/CRUDTable/CRUDTable";
import { CRUDTableHeader } from "../../../../../Partials/Layout/CRUD/CRUDTableHeader/CRUDTableHeader";
import { CRUDth } from "../../../../../Partials/Layout/CRUD/CRUDth/CRUDth";
import { Modal } from "../../../../../Partials/Elements/Modal/Modal";
import ViewBankDetails from "./ViewBankDetails";
import { Link } from "react-router-dom";

import RowItem from "./rowItem/RowItem";

function TokenInvestors({ api }) {
  const [toggleFetch, setToggleFetch] = useState(false);
  const axiosInstance = useAxiosInstance();
  const [showBankDoc, setShowBankDoc] = useState(false);
  const [selectedBankData, setSelectedBankData] = useState(null);

  const { info: tokenInvestors } = useGetInfo({
    axiosInstance: axiosInstance,
    api: api + "getAllTokenInvestors",
    toggleFetch,
  });

  const handleBankDetails = (bankData) => {
    setSelectedBankData(bankData);
    setShowBankDoc(true);

  };
  return (
    <div className="screen_wrapper">
      <div className="screen_header">
        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <BackButton />
          <h1 className="screen_heading">{`Nano Assets Investors (${tokenInvestors?.length} in total)`}</h1>
        </div>
        <Link to="/main/nanoAssetInvestorInvites">
          <button className="submit">Investor Invitations</button>
        </Link>
      </div>
      <CRUDTable>
        <CRUDTableHeader>
          {/* <CRUDth th="Image" /> */}
          <CRUDth align={"left"} th="Name" />
          <CRUDth th="Email Address" />
          <CRUDth th="Bank Account" />
          {/* <CRUDth th="Level" /> */}
          <CRUDth th="Transactions" />
        </CRUDTableHeader>
        <tbody>
          {tokenInvestors &&
            tokenInvestors?.map((e) => <RowItem key={e._id} e={e} />)}
        </tbody>
      </CRUDTable>

      {showBankDoc && (
        <Modal
          extraClass={"small"}
          modalHeading={`View Bank Details`}
          setShowModalContent={setShowBankDoc}
        >
          <ViewBankDetails bankData={selectedBankData} />
        </Modal>
      )}
    </div>
  );
}

export default TokenInvestors;

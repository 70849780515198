import "./SelectOption.css";

function SelectOption({ className, optionValue, optionText, hidden, disabled }) {
  return (
    <option
      className={`${className} text-black`}
      value={optionValue}
      hidden={hidden}
      disabled={disabled}
    >
      {optionText}
    </option>
  );
}

export default SelectOption;

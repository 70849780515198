
/**
 * Calculates the number of days from today until the target date.
 * @param {string | Date} date - The target date.
 * @returns {number} - Number of days until the target date. Returns 0 if the date has passed.
 */
export function getDaysUntil(date) {
    const today = new Date();
    const targetDate = new Date(date);
    
    // Clear the time part for accurate day calculation
    today.setHours(0, 0, 0, 0);
    targetDate.setHours(0, 0, 0, 0);
    
    const diffInTime = targetDate - today;
    const diffInDays = Math.ceil(diffInTime / (1000 * 60 * 60 * 24));
    
    return diffInDays > 0 ? diffInDays : 0; // Ensure no negative values
  }
  
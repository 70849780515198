const InputField = ({ label, type = 'text', value, placeholder }) => (
    <div className="flex flex-col">
      <label className="text-white mb-2">{label}</label>
      <input
        type={type}
        placeholder={placeholder}
        className="w-full py-4 px-6 border rounded-md text-[#828287] !bg-[#171717]"
        value={value}
        readOnly
      />
    </div>
  );
  export default InputField;
import React from "react";

import { RiArrowLeftLine, RiGroup2Line } from "react-icons/ri";
import { FaBriefcase } from "react-icons/fa";
import { ScreenHolder } from "../../../Partials/Layout/ScreenHolder/ScreenHolder";
import { NavCardList } from "../../../Partials/Layout/NavCardList/NavCardList";
import { NavCard } from "../../../Partials/Layout/NavCardList/NavCard/NavCard";
import { Link, useNavigate } from "react-router-dom";
import GoToDashboard from "../../../Partials/Elements/GoToDashboard";

function WCScreen() {

 
  return (
    <ScreenHolder>
     <GoToDashboard/>
      <NavCardList numOfCards={"four"}>
        <NavCard cardName={"WC Investors"} navCardLink="/main/investors">
          <RiGroup2Line />
        </NavCard>
        <NavCard cardName={"Companies"} navCardLink="/main/companies">
          <RiGroup2Line />
        </NavCard>
        <NavCard cardName={"Investments"} navCardLink="/main/allInvestments">
          <FaBriefcase />
        </NavCard>
        <NavCard cardName={"Investment User"} navCardLink="/main/investorUsers">
          <RiGroup2Line />
        </NavCard>
      </NavCardList>
    </ScreenHolder>
  );
}

export default WCScreen;

export function CRUDth({ th, align, onClick, style }) {
  return (
    <th
      onClick={onClick}
      className="whitespace-nowrap"
      style={{
        textAlign: align ? align : "center",
        fontSize: "16px",
        ...style,
      }}
    >
      {th}
    </th>
  );
}

import { IconClose } from 'antopolis-react-icons';

export function ModalHead({ modalHeading, setShowModalContent }) {
  return (
    <div className='flex justify-between items-center pb-8'>
      <h1 className="text-[2.4rem] font-bold text-white">{modalHeading}</h1>
      <button 
        type='button' 
        className='text-2xl text-gray-400 hover:text-gray-500'
        onClick={() => setShowModalContent(false)}
      >
        <IconClose />
      </button>
    </div>
  )
}
import React, { useState } from "react";
import { takaFormatter } from "../../../../../Utilities/HelperFunctions/takaFormatter";
import { formatDate } from "../../../../../Utilities/HelperFunctions/formatDate";
import { IconClose } from 'antopolis-react-icons';
import ExpiresIn from "../../../../Partials/Elements/ExpiresIn";
import NewProgressBar from "../../../../Partials/Elements/NewProgressBar";

const NanoAssetModal = ({ isOpen, onClose, nanoAsset }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const maxDescriptionLength = 200;

  if (!isOpen || !nanoAsset) return null;

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  const renderDescription = () => {
    const description = nanoAsset?.token?.tokenDescription;
    if (!description) return "No description";

    const shouldTruncate = !isExpanded && description.length > maxDescriptionLength;
    const truncatedDescription = shouldTruncate
      ? description.slice(0, maxDescriptionLength) + "..."
      : description;

    return (
      <div>
        <div
          className="font-medium"
          dangerouslySetInnerHTML={{ __html: truncatedDescription }}
        />
        {description.length > maxDescriptionLength && (
          <button onClick={toggleReadMore} className="ml-2 text-blue-500">
            {isExpanded ? "Show Less" : "Read More"}
          </button>
        )}
      </div>
    );
  };

  return (
    <div
      className="fixed inset-0 bg-gray-500 bg-opacity-[82%] flex items-center justify-center z-50"
      onClick={onClose}
    >
      <div
        className="bg-black rounded-lg shadow-lg p-6 relative text-white w-2/3 max-w-screen-xl "
        onClick={(e) => e.stopPropagation()}
      >
        <button
          onClick={onClose}
          className="text-2xl font-bold text-gray-600 hover:text-gray-800 absolute right-12 top-12"
        >
          <IconClose />
        </button>
        <div className="grid grid-cols-3 gap-4 space-x-20 m-12">
          {/* Left Column: Token Thumbnail */}
          <div className="col-span-1 flex items-center justify-center">
            {nanoAsset.token.tokenThumbnail && (
              <img
                src={process.env.REACT_APP_SPACES_URL + nanoAsset.token.tokenThumbnail}
                alt={nanoAsset.token.tokenName}
                className="w-full h-auto object-contain rounded-md"
              />
            )}
          </div>
          {/* Right Column: Details */}
          <div className="col-span-2 flex flex-col justify-center">
            <h3 className="text-white text-[1.8rem] font-semibold mb-2">
              {nanoAsset.token.tokenName}
            </h3>
            <div className="mb-4 text-[2rem]">{renderDescription()}</div>
            <p className="text-[1.8rem] text-[#8A8A8A] font-normal flex justify-between">
                  Projected Annualised Return          
                  <span className="text-[#34A853]  font-normal">
                    {nanoAsset?.token?.maxRoi
                      ? `${nanoAsset?.token?.roi} - ${nanoAsset?.token?.maxRoi}%`
                      : `${nanoAsset?.token?.roi}%`}
                  </span>
                </p>
                <p className="text-[1.6rem] flex justify-between text-[#8A8A8A]  font-normal">
                  Maturity Period{" "}
                  <span className="text-[#8A8A8A] font-semibold">
                    {nanoAsset?.token?.lockInPeriod} days
                  </span>
                </p>

                {nanoAsset?.token?.finalInvestmentDate && (
                  <ExpiresIn
                    finalInvestmentDate={nanoAsset.token?.finalInvestmentDate}
                    textSize={"text-[1.8rem]"}
                  />
                )}
                <p className="text-[1.8rem] flex justify-between text-[#8A8A8A] font-normal">
                  Risk score
                  <p
                    className={`px-4 rounded-3xl ${
                      nanoAsset?.token?.riskScore === "High" &&
                      "bg-rose-200 text-rose-900"
                    } 
            ${
              nanoAsset?.token?.riskScore === "Medium" && "bg-yellow-100 text-black"
            }
            ${
              nanoAsset?.token?.riskScore === "Low" && "bg-green-200 text-green-900"
            }`}
                  >
                    {nanoAsset?.token?.riskScore || "No Risk Score Available"}
                  </p>
                </p>

                <NewProgressBar
              tokenSold={nanoAsset?.token?.quantity}
               totalTokens={nanoAsset?.token?.totalIssuedTokens}
            />
             <div className=" text-lg text-[#8A8A8A] lg:text-[1.8rem] ">
        <p className="whitespace-nowrap font-normal mb-1">Per Token Price: </p>
        <div
          className={`text-yellow-500 flex items-center gap-4 font-bold `}
        >
          <span className="text-nowrap">
            TK. {nanoAsset?.token?.initialTokenValue || nanoAsset?.initialTokenValue}
          </span>

        </div>
      </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default NanoAssetModal;

import { useState, useEffect } from "react";

export default function NewProgressBar({ tokenSold, totalTokens, isSimple }) {
  const [animatedAmount, setAnimatedAmount] = useState(0);
  const validTokenSold = Math.max(0, tokenSold || 0);
  const validTotalTokens = Math.max(0, totalTokens || 0);

  useEffect(() => {
    const actualTokenSold = validTotalTokens - validTokenSold;

    const increment = actualTokenSold / 50;
    const interval = setInterval(() => {
      setAnimatedAmount((prevAmount) => {
        const nextAmount = prevAmount + increment;
        if (nextAmount >= actualTokenSold) {
          clearInterval(interval);
          return actualTokenSold;
        }
        return nextAmount;
      });
    }, 20);
    return () => clearInterval(interval);
  }, [validTokenSold, validTotalTokens]);

  const percentageSold =
    validTotalTokens > 0 ? (animatedAmount / validTotalTokens) * 100 : 0;

  return (
    <div className="w-full py-4  mt-20">
      <div
        className={`justify-between items-center ${
          isSimple ? "hidden" : "flex"
        }`}
      >
        <p className="flex items-center gap-2 text-xl text-white text-[1.8rem] md:text-2xl">
          Remaining ({tokenSold})
        </p>
        <p className="text-xl text-white text-[1.8rem] md:text-xl">{totalTokens}</p>
      </div>
      <div className="relative w-full h-4 mt-2 bg-orange-200 rounded">
        <div
          className="absolute h-full bg-[#FEC241] rounded"
          style={{
            width: `${Math.min(percentageSold, 100)}%`, // Clamp percentage to 100
            transition: "width 0.3s ease-in-out",
          }}
        ></div>
      </div>
    </div>
  );
}

import { useState, useEffect } from "react";
import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance";
import { MANAGE_ALLINVESTMENTS_API } from "../../../../../Utilities/APIs/APIs";
import BackButton from "../../../../Partials/Elements/BackButton/BackButton";
import { CRUDTable } from "../../../../Partials/Layout/CRUD/CRUDTable/CRUDTable";
import { CRUDTableHeader } from "../../../../Partials/Layout/CRUD/CRUDTableHeader/CRUDTableHeader";
import { CRUDth } from "../../../../Partials/Layout/CRUD/CRUDth/CRUDth";
import { ShortTextCell } from "../../../../Partials/Layout/CRUD/ShortTextCell/ShortTextCell";
import { takaFormatter } from "../../../../../Utilities/HelperFunctions/takaFormatter";
import { CSVLink } from "react-csv";
import { useParams } from "react-router-dom";

function CompanyInvestments({ api }) {
    const [investments, setInvestments] = useState([]);
    const axiosInstance = useAxiosInstance();
    const { companyId } = useParams();

    useEffect(() => {
        const fetchInvestments = async () => {
            try {
                const { data } = await axiosInstance.get(`${MANAGE_ALLINVESTMENTS_API}getInvestmentsByCompany/${companyId}`);
                setInvestments(data || []);
            } catch (error) {
            }
        };

        fetchInvestments();
    }, [api, axiosInstance, companyId]);

    const csvData = investments.map(inv => ({
        company: inv?.company?.name || 'N/A',
        amount: inv?.amount || 0,
        roi: inv?.rate ? `${inv.rate}%` : 'N/A',
        tenure: inv?.tenure || 'N/A',
    }));

    return (
        <div className="screen_wrapper">
            <div className="screen_header">
                <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                    <BackButton />
                    <h1 className="screen_heading">
                        {`${investments.length > 0 ? investments[0]?.company?.name || "N/A" : ""} Investments (${investments.length} in total)`}
                    </h1>
                </div>
                <div style={{ display: "flex", gap: "10px" }}>
                    <CSVLink data={csvData} filename="investments.csv" className="text-yellow-400">
                        Export To CSV
                    </CSVLink>
                </div>
            </div>
            <CRUDTable>
                <CRUDTableHeader>
                    <CRUDth th="Investor" />
                    <CRUDth th="Amount" />
                    <CRUDth th="Annualized ROI (%)" />
                    <CRUDth th="Tenure (Days)" />
                </CRUDTableHeader>
                <tbody>
                    {investments.map(investment => (
                        <tr key={investment?._id}>
                            <ShortTextCell text={investment?.investor?.name || 'N/A'} />
                            <ShortTextCell text={investment?.amount ? takaFormatter(investment.amount) : 'N/A'} />
                            <ShortTextCell text={investment?.rate ? `${investment.rate}%` : 'N/A'} />
                            <ShortTextCell text={investment?.tenure || 'N/A'} />
                        </tr>
                    ))}
                </tbody>
            </CRUDTable>
        </div>
    );
}

export default CompanyInvestments;

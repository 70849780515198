import { useState, useEffect } from "react";
import { getDaysUntil } from "./dateUtils";


const ExpiresIn = ({ finalInvestmentDate, textSize, isWishList = false }) => {
  const [daysUntilExpiration, setDaysUntilExpiration] = useState(null);

  useEffect(() => {
    if (finalInvestmentDate) {
      const days = getDaysUntil(finalInvestmentDate);
      setDaysUntilExpiration(days);
    }
  }, [finalInvestmentDate]);

  return (
    <p
      className={`line-clamp-1 text-[#8A8A8A] font-normal flex  ${
        textSize ? textSize : "text-[1.8rem]"
      } ${isWishList ? "justify-start gap-4" : "justify-between"}`}
    >
      Expires in{" "}
      <span className="text-[#8A8A8A] font-semibold">
        {daysUntilExpiration} {daysUntilExpiration === 1 ? "day" : "days"}
      </span>
    </p>
  );
};

export default ExpiresIn;

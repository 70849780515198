import React, { useEffect, useState } from 'react';
import { MANAGE_BANK_DETAILS_API } from '../../../../../Utilities/APIs/APIs';
import { useGetInfo } from 'antopolis-react-utils/hooks';
import { useAxiosInstance } from '../../../../../Utilities/Hooks/useAxiosInstance';
import SelectInput from '../../../../Partials/Forms/FormInputs/SelectInput/SelectInput';
import SelectOption from '../../../../Partials/Forms/FormInputs/SelectInput/SelectOption/SelectOption';
import InputField from './UserModal/InputField';

const UserDetails = ({ selectedUserInfo }) => {
 const axiosInstance = useAxiosInstance();


 const [bankDetails, setBankDetails] = useState(null);

 const { info } = useGetInfo({
   axiosInstance: axiosInstance,
   api: `${MANAGE_BANK_DETAILS_API}/investorBankDetails/${selectedUserInfo?.tokenInvestor._id}`,
 });

 useEffect(() => {
   if (info && info.bankDetails) {
     setBankDetails(info.bankDetails);
   }
 }, [info]);
      
  return (
    <div className="w-full mx-auto p-6  shadow rounded-md-lg">
      <div className='flex flex-col items-center justify-center mb-6 '>
        <img 
          src={process.env.REACT_APP_SPACES_URL + selectedUserInfo?.tokenInvestor?.dp} 
          alt="User Avatar"  
          className='w-40 h-40 rounded-full'
        />
      </div>
      
      <div className=" grid grid-cols-2 gap-6">
        {/* Profile Info Section */}
       
        <div>
          <h3 className="text-[2rem] font-semibold mb-4 text-white">Profile Info</h3>
          <div className="space-y-3">
          <InputField label="Full Name" value={selectedUserInfo.tokenInvestor.name} placeholder="Full Name" />
            <InputField label="Email" type="email" value={selectedUserInfo.tokenInvestor.email} placeholder="Email" />
            <InputField label="Phone Number" value={selectedUserInfo.tokenInvestor.phoneNumber || 'No Number'} placeholder="Phone Number" />
            <InputField label="Address" value={selectedUserInfo.tokenInvestor.address || 'No address'} placeholder="Address" />
           <div className="flex flex-col">
           <label className="text-white">Bank Account</label>
           <SelectInput setState={() => {}} 
                
                >
              {bankDetails ? (
                bankDetails.map((bank) => (
                  <SelectOption
                   className="text-[#828287] "
                    key={bank._id}
                    optionValue={bank._id}
                    optionText={bank.bankName}
                   
                  />
                ))
              ) : (
                <SelectOption
                 className="text-[#828287] "
                  optionValue="No accounts"
                  optionText="No accounts"
                />
              )}
            </SelectInput>
           </div>
          </div>
        </div>
        
        {/* Add Bank Section */}
       {/* Bank Details Section */}
       <div>
          <h3 className="text-[2rem] font-semibold mb-4 text-white">Bank Details</h3>
          {bankDetails ? (
            bankDetails.map((bank, index) => (
              <div key={index} className="space-y-3 mb-4">
                <InputField label="Bank Name" value={bank.bankName} placeholder="Bank Name" />
                <InputField label="Branch Name" value={bank.branchName} placeholder="Branch Name" />
                <InputField label="Account Name" value={bank.accountName} placeholder="Account Name" />
                <InputField label="Account Number" value={bank.accountNumber} placeholder="Account Number" />
                <InputField label="Routing Number" value={bank.routing} placeholder="Routing Number" />
             
              </div>
            ))
          ) : (
            <p>No bank details available.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserDetails;

export default function ViewReceipt({ imageSrc }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        style={{
          objectFit: "contain",
          height: "100%",
          width: "100%",
          maxWidth: "800px",
          maxHeight: "600px",
        }}
        src={imageSrc}
        alt="Document"
      />
    </div>
  );
}

// export function ShortTextCell({ text, align }) {
//   return <td className="whitespace-nowrap" style={{ textAlign: align || "center", fontSize: "16px" }}>{text}</td>;
// }

export function ShortTextCell({ onClick, text, tooltipText, align }) {
  return (
    <td
    onClick={onClick}
      className="whitespace-nowrap relative group"
      style={{
        textAlign: align || "center",
        fontSize: "16px",
      }}
    >
      <span className="truncate relative">
        {text}

        {tooltipText && (
          <span className="absolute hidden bottom-2 -right-28 group-hover:block bg-gray-700/95 text-white text-lg rounded py-1 px-2 z-10">
            {tooltipText}
          </span>
        )}
      </span>
    </td>
  );
}

import React, { useState, useEffect } from "react";
import { ScreenHolder } from "../../../Partials/Layout/ScreenHolder/ScreenHolder";
import Topbar from "./components/Topbar";
import TotalAssetList from "./components/TotalAssetList";
import StatsLayout from "./components/StatsLayout";
import { MANAGE_STATISTICS_API } from "../../../../Utilities/APIs/APIs";
import { useGetInfo } from "antopolis-react-utils/hooks";
import { useAxiosInstance } from "../../../../Utilities/Hooks/useAxiosInstance";

function Dashboard() {
  const [wcInvestorFilter, setWcInvestorFilter] = useState("total");
  const [companyFilter, setCompanyFilter] = useState("total");
  const [loading, setLoading] = useState(true);
  const [reversedNanoInvestmentData, setReversedNanoInvestmentData] = useState([]);
  const [reversedCompanyInvestmentData, setReversedCompanyInvestmentData] = useState([]);
  const [topFourInvestedNanoAssets, setTopFourInvestedNanoAssets] = useState([]);
  const [topFourInvestedCompanies, setTopFourInvestedCompanies] = useState([]);

  const axiosInstance = useAxiosInstance();
  
  const { info } = useGetInfo({
    axiosInstance: axiosInstance,
    api: MANAGE_STATISTICS_API + "getStatistics",
  });
  useEffect(() => {
    if (info?.data) {
      setLoading(false);

      // Reverse the investment data for Nano Assets and Companies
      if (info?.data?.totalCompanyInvestmentByYear) {
        const reversedCompanyData = [...info?.data?.totalCompanyInvestmentByYear]?.reverse();
        setReversedCompanyInvestmentData(reversedCompanyData);
      }
      
      if (info?.data?.totalNanoAssetInvestmentByYear) {
        const reversedNanoAssetData = [...info?.data?.totalNanoAssetInvestmentByYear]?.reverse();
        setReversedNanoInvestmentData(reversedNanoAssetData);
      }

      // Set Top 4 Invested Companies
      if (info?.data?.topInvestedCompanies) {
        setTopFourInvestedCompanies(info?.data?.topInvestedCompanies);
      }

      // Set Top 4 Invested Nano Assets
      if (info?.data?.topNanoAssetsInvested) {
        setTopFourInvestedNanoAssets(info?.data?.topNanoAssetsInvested);
      }
    }
  }, [info?.data]);

  const data = info?.data;

  const getWcInvestorCount = () => {
    if (wcInvestorFilter === "active") {
      return data?.totalActiveInvestor;
    } else if (wcInvestorFilter === "archived") {
      return data?.totalArchivedInvestor;
    } else {
      return data?.totalActiveInvestor + data?.totalArchivedInvestor;
    }
  };

  const getCompanyCount = () => {
    if (companyFilter === "active") {
      return data?.totalActiveCompanies;
    } else if (companyFilter === "archived") {
      return data?.totalArchivedCompanies;
    } else {
      return data?.totalActiveCompanies + data?.totalArchivedCompanies;
    }
  };

  if (loading) {
    return (
      <ScreenHolder>
        <div className="flex items-center justify-center h-screen">
          <div className="text-white text-3xl">Loading...</div>
        </div>
      </ScreenHolder>
    );
  }
  return (
    <div>
      <ScreenHolder>
        <Topbar />
        <TotalAssetList
          totalWCInvestor={data?.totalWCInvestor}
          totalCompany={data?.totalCompanies}
          totalNanoAssetInvestors={data?.totalNanoAssetInvestors}
          totalNanoAssets={data?.totalNanoAssetNumber}
          totalPendingBuyTokenRequests={data?.totalPendingBuyTokenRequests}
          totalPendingSellTokenRequests={data?.totalPendingSellTokenRequests}
        />
        <StatsLayout
          reversedNanoInvestmentData={reversedNanoInvestmentData}
          reversedCompanyInvestmentData={reversedCompanyInvestmentData}
          totalPendingBuyTokenRequests={data?.totalPendingBuyTokenRequests}
          totalPendingSellTokenRequests={data?.totalPendingSellTokenRequests}
          topFourInvestedNanoAssets={topFourInvestedNanoAssets}
          topFourInvestedCompanies={topFourInvestedCompanies} 
        />
      </ScreenHolder>
    </div>
  );
}

export default Dashboard;

import { useEffect, useState } from "react";
import { useAxiosInstance } from "../../../../../../../../Utilities/Hooks/useAxiosInstance";
import { MANAGE_INVESTMENT_DOCUMENTS_API } from "../../../../../../../../Utilities/APIs/APIs";
import Form from "../../../../../../../Partials/Forms/Form";
import SwitchInput from "../../../../../../../Partials/Forms/FormInputs/SwitchInput/SwitchInput"; // Switch input for toggling archive state
import FormSubmitButton from "../../../../../../../Partials/Forms/FormSubmitButton/FormSubmitButton";

export default function ArchiveDocument({ targetID, documentID, setShowEditSection, triggerFetch }) {
    const axiosInstance = useAxiosInstance();
    const [isActive, setIsActive] = useState(true);


    
    // Fetch the existing document data to populate the form
    useEffect(() => {
        async function fetchDocumentData() {
            try {
                const response = await axiosInstance.get(
                    MANAGE_INVESTMENT_DOCUMENTS_API + "getSingleDocument/" + documentID
                );
                if (response?.data) {
                    setIsActive(response.data.isActive); // Set active status from response
                }
            } catch (error) {
            }
        }

        fetchDocumentData();
    }, [axiosInstance, documentID]);

    async function handleSubmit(e) {
        e.preventDefault();

        const formData = {
            investment: targetID,
            isActive, // Pass the active/archive state
        };

        try {
            const response = await axiosInstance.put(
                MANAGE_INVESTMENT_DOCUMENTS_API + "archiveDocument/" + documentID,
                formData
            );

            if (response?.data) {
                setShowEditSection(false);
                triggerFetch();
            }
        } catch (error) {
        }
    }

    return (
        <Form onSubmit={handleSubmit}>
            <div>
                {/* Switch input to toggle between active/inactive (archive) */}
                <SwitchInput
                    label="Active Status"
                    checked={isActive}
                    toggleSwitch={() => setIsActive(!isActive)}
                />
            </div>
            <FormSubmitButton text="Update Document" />
        </Form>
    );
}
